import { Connectivity, PriceDetailPosition } from '@bytel/product-wall/types';
import { IconName } from '@bytel/trilogy-react-ts/lib/components/icon/IconNameEnum';
import { VariantState } from '@bytel/trilogy-react-ts/lib/objects/facets/Variant';

import { getAppConfig } from '@services/config';

import {
    SapicFundingType,
    SapicProductTypeV4,
    SapicProductTypeV5,
    SimulatedCartQuoteV4,
    SimulatedCartV4,
    SimulatedCartV5,
} from '@app-types/api/sapic';
import { ParcoursVirtuel } from '@app-types/api/vapi';
import { QUOTE_TYPES } from '@app-types/cart';
import { DiscountEnumType } from '@app-types/discount';
import { FundingEnumType, FundingType } from '@app-types/funding';
import { FaiType, ObligationType, PlanNugget, PlanType } from '@app-types/plan';
import { PRICE_TYPE } from '@app-types/price';
import { Product, ProductType, SimulatedCartProduct } from '@app-types/product';
import { SimulatedQuote } from '@app-types/simulator/virtual-cart';

import { ApiDiscountMapper } from '@helpers/mappers/api-discount.mapper';
import { getProductUrlByType } from '@helpers/product';

import { PlanRange, PlanTypeId } from '@constants/plan';
import { PLAY, TECHNOLOGY } from '@constants/provider';

const appConfig = getAppConfig();
export class SapicMapper {
    static mapToProduct(sapicProduct: SapicProductTypeV4 | SapicProductTypeV5, isRenewal: boolean = false): Product {
        if (!sapicProduct) {
            return {} as Product;
        }

        const funding = sapicProduct.financements?.[0];
        let instalment: Product['instalment'] | undefined;
        const isFunding = !!funding && funding.type !== FundingEnumType.COMPTANT;

        if (isFunding) {
            instalment = {
                amount: funding.montantMensualite,
                count: funding.nbrEcheances,
            };
        }

        const initialDeposit = funding?.apportInitial ?? 0;
        const sumODRDiscounts = sapicProduct.promotions.reduce((sum, { reduction, type }) => {
            if (type !== DiscountEnumType.ODR) {
                return sum;
            }
            return sum + reduction;
        }, 0);
        const priceWithOdr = initialDeposit - sumODRDiscounts;
        const price = priceWithOdr < 1 ? 1 : priceWithOdr;

        const hasCashbackOffer =
            sapicProduct.promotions.some(({ type }) => type === DiscountEnumType.ODR) && initialDeposit > 1;
        const instantDiscount = (sapicProduct.prix.subventionne || sapicProduct.prix.initial) - sapicProduct.prix.final;

        const simulatedCart = sapicProduct.panierSimule as SimulatedCartV4 | SimulatedCartV5;

        return {
            id: sapicProduct.url,
            gencode: sapicProduct.gencode,
            urlKey: sapicProduct.url,
            url: getProductUrlByType(sapicProduct.url, sapicProduct.type, isRenewal),
            inStock: sapicProduct.estEnStock,
            brand: sapicProduct.marque,
            name: sapicProduct.nom,
            image: sapicProduct.image,
            categories: sapicProduct.categories,
            stickers: sapicProduct.etiquetteAnimCo
                ? [{ label: sapicProduct.etiquetteAnimCo, color: VariantState.PRIMARY }]
                : undefined,
            priceFrom: isFunding,
            price,
            instalment,
            priceDetail: SapicMapper.getPriceDetail(isFunding, instantDiscount, hasCashbackOffer),
            recommended: sapicProduct.recommande ?? false,
            details: {
                price: {
                    initial: sapicProduct.prix.initial,
                    subsidized: sapicProduct.prix.subventionne,
                    forever: sapicProduct.prix.pourToujours,
                    final: sapicProduct.prix.final,
                },
                // v4
                discounts: sapicProduct.promotions.map(ApiDiscountMapper.mapPromotionSapic),
                fundings: sapicProduct?.financements?.map(SapicMapper.fundingMapper) ?? [],
            },
            colors: sapicProduct?.couleurs ?? [],
            products: [],
            simulatedCart: SapicMapper.getSimulatedCart(simulatedCart),
        };
    }

    static createSimulatedQuote(quote: SimulatedCartQuoteV4 | ParcoursVirtuel): SimulatedQuote {
        return {
            products: [],
            type: QUOTE_TYPES.ACQUISITION,
            current: 'estCourant' in quote ? quote.estCourant : false,
        };
    }

    static createSimulatedProducts(quote: SimulatedCartQuoteV4 | ParcoursVirtuel): Product[] {
        const products: Product[] = [];
        if ('elements' in quote) {
            quote.elements.forEach((product) => {
                const simulatedProduct: Partial<Product> = {
                    gencode: product.composantCommercial.identifiant,
                    details: {
                        price: {
                            initial: product.prixAffiche.prixCatalogue,
                            final: product.prixAffiche.prixAvecPromotions,
                            forever: product.prixAffiche.prixPourToujours,
                            subsidized: product.prixAffiche.prixPourToujours,
                            type: product.prixAffiche.type as PRICE_TYPE,
                        },
                        discounts: product.promotionsCalculees.promotionsAppliquees.map(
                            ApiDiscountMapper.mapPromotionVapi,
                        ),
                        fundings: [], // @TODO: when SAPIC V5 will have funding
                    },
                    type: ProductType.ACCESSORY, // @TODO: when SAPIC V5 will have type
                };
                products.push(simulatedProduct as Product);
            });
        } else {
            quote.produits.forEach((product) => {
                const simulatedProduct: Partial<Product> = {
                    gencode: product.gencode,
                    details: {
                        price: {
                            initial: product.prix.initial,
                            final: product.prix.final,
                            forever: product.prix.pourToujours,
                            subsidized: product.prix.subventionne,
                            type: product.typePrix as PRICE_TYPE,
                        },
                        discounts: product.promotions.map(ApiDiscountMapper.mapPromotionSapic),
                        fundings: product.financements.map(SapicMapper.fundingMapper),
                    },
                    type: product.type as ProductType, // @TODO: create mapper sapic type => our type
                    brand: product.marque,
                };
                products.push(simulatedProduct as Product);
            });
        }

        return products;
    }

    static getSimulatedCart(cart: SimulatedCartV4 | SimulatedCartV5): SimulatedCartProduct | undefined {
        if (!cart || !cart.parcours) {
            return undefined;
        }

        const simulatedCart: SimulatedCartProduct = {
            quotes: [],
        };

        cart.parcours.forEach((quote) => {
            const simulatedQuote: SimulatedQuote = SapicMapper.createSimulatedQuote(quote);

            simulatedQuote.products = SapicMapper.createSimulatedProducts(quote);
            simulatedCart.quotes.push(simulatedQuote);
        });

        return simulatedCart;
    }

    static fundingMapper(funding: SapicFundingType): FundingType {
        return {
            type: funding.type,
            favorite: funding.prefere,
            initialDeposit: funding.apportInitial,
            amountToFinance: funding.montantAFinancer,
            totalFinancingCost: funding.coutTotalFinancement,
            APR: funding.tAEG,
            interestAmount: funding.montantInteret,
            fixedInterestRate: funding.tauxDebiteurFixe,
            installmentCount: funding.nbrEcheances,
            label: SapicMapper.getFundingLabels(funding),
            monthlyAmount: funding.montantMensualite,
        };
    }

    static getFundingLabels(funding: SapicFundingType): string {
        switch (funding.type) {
            case FundingEnumType.COMPTANT:
                return 'Comptant';
            case FundingEnumType.EDP:
                return 'Facilité de paiement sans frais';
            default: {
                const fundingLabel = funding.tAEG === 0 ? `${funding.tAEG}%` : '';
                return `Financement smartphone ${fundingLabel}`;
            }
        }
    }

    static mapToFai(apiData: SapicProductTypeV5): FaiType {
        const product = SapicMapper.mapToProduct(apiData, false);
        const faiData: Partial<FaiType> = {
            downRates: apiData.debitDescendant || '',
            upRates: apiData.debitAscendant || '',
            rentalFees: apiData.fraisDeLocation || 0,
            technology: apiData.technologie as TECHNOLOGY,
            play: apiData.jeu as PLAY,
            categoryType: apiData.typeCategorieFai || '',
            obligation: apiData?.dureeEngagement || ObligationType.NONE,
            obligationLabel: SapicMapper.getObligationLabel(apiData?.dureeEngagement, true),
            commercialSeries: apiData.serieCommerciale ?? apiData.classification,
            rangeNg: apiData.gammeNg ?? 'xgbox',
            range: apiData.gamme,
            type: apiData.type,
        };
        return { ...product, ...faiData } as FaiType;
    }

    static mapToPlan(apiData: SapicProductTypeV4 | SapicProductTypeV5): PlanType {
        const product = SapicMapper.mapToProduct(apiData, false);
        let planDataV5: Partial<PlanType> = {};

        const planData: Partial<PlanType> = {
            gencode: apiData.gencode,
            price: apiData.prix.initial,
            products: [],
            fundings: [],
            typeId: SapicMapper.mapSapicTypeToPlanType(apiData.type),
            obligation: apiData?.dureeEngagement || ObligationType.NONE,
            obligationLabel: SapicMapper.getObligationLabel(apiData?.dureeEngagement, apiData.type === ProductType.FAI),
            name: apiData.nom.replace('Avantages Smartphone', ''),
            dataEnvelope: apiData.libelleEnveloppeData || '',
            connectivity: SapicMapper.mapSapicConnectivityToConnectivity(apiData.connectivite),
            isDarwin: false,
            hasDoubleSim: false,
            hasLowPrice: false,
            rangeNg: apiData.gammeNg ?? 'xgbox',
            range: apiData.gamme,
            commercialSeries: apiData.serieCommerciale ?? apiData.classification,
            technology: apiData.technologie,
            renewalLabelDataEnvelope: apiData.libelleEnveloppeData ?? '',
            nuggets: SapicMapper.getPlanNuggets(apiData),
            isDefault:
                appConfig.defaultPlans.simulator.mobile === apiData.gencode ||
                appConfig.defaultPlans.simulator.fai === apiData.gencode ||
                false,
        };

        // v5
        if ('debitDescendant' in apiData) {
            planDataV5 = {
                downRates: apiData.debitDescendant,
                upRates: apiData.debitAscendant,
                commercialRange: apiData.gammeCommerciale as PlanRange,
            };
        }

        if ('fraisDeLocation' in apiData) {
            planDataV5 = {
                ...planDataV5,
                rentalFees: apiData.fraisDeLocation || 0,
            };
        }

        return { ...product, ...planData, ...planDataV5 } as PlanType;
    }

    static getObligationLabel(obligation?: ObligationType, isYears: boolean = false): string {
        switch (obligation) {
            case ObligationType.MONTHLY_24:
                return `engagement ${isYears ? '2 ans' : '24 mois'}`;
            case ObligationType.MONTHLY_12:
                return `engagement ${isYears ? '1 an' : '12 mois'}`;
            default:
                return 'sans engagement';
        }
    }

    static mapSapicConnectivityToConnectivity(connectivity: string | undefined): Connectivity | undefined {
        switch (connectivity) {
            case '5g':
                return Connectivity['5G'];
            case '4g+':
                return Connectivity['4G+'];
            default:
                return Connectivity['4G'];
        }
    }

    static mapSapicTypeToPlanType(type: string): PlanTypeId {
        const typeValues = Object.values(PlanTypeId);
        const typeValue = typeValues.find((value) => value === type);
        return typeValue || PlanTypeId.DEFAULT;
    }

    static getPlanNuggets(apiData: SapicProductTypeV5 | SapicProductTypeV4): PlanNugget[] {
        const nuggets: PlanNugget[] = [];

        const nugget5g: PlanNugget = {
            icon: IconName.UI_5G,
            label: '5G',
            description: 'Forfait 5G',
        };

        const nugget4g: PlanNugget = {
            icon: IconName.UI_4G,
            label: '4G',
            description: 'Forfait 4G',
        };

        if (apiData.connectivite === '5g') {
            nuggets.push(nugget5g);
        } else {
            nuggets.push(nugget4g);
        }

        if (apiData.libelleEnveloppeVoix === 'Appels illimités') {
            nuggets.push({
                icon: IconName.AIR_TRAVEL,
                label: 'Appels illimités',
                description: 'Appels illimités',
            });
        }

        return nuggets;
    }

    static getPriceDetail(
        isFunding: boolean,
        instantDiscount: number,
        hasCashbackOffer: boolean,
    ): { position: PriceDetailPosition; text?: string } | undefined {
        if (hasCashbackOffer) {
            return {
                position: PriceDetailPosition.BELOW,
                text: 'après remboursement',
            };
        }

        if (instantDiscount > 0) {
            return {
                position: PriceDetailPosition.BELOW,
                text: 'après remise',
            };
        }

        if (isFunding) {
            return {
                position: PriceDetailPosition.BESIDE,
            };
        }
    }
}
