export enum PlanTypeId {
    DEFAULT = 'default',
    PREMIUM = 'plan_premium',
    PREMIUM_SELECTION = 'plan_premium_selection',
    PREMIUM_PRO = 'plan_pro_premium',
    PREMIUM_RENEWAL = 'plan_premium_renew',
    PREMIUM_ENTRY = 'plan_premium_entry',
    PREMIUM_MULTILIGNE = 'plan_premium_multiligne',
    SIMO = 'plan_simo',
    SIMO_SELECTION = 'plan_simo_selection',
    SIMO_ENTRY = 'plan_simo_entry',
    SIMO_MULTILIGNE = 'plan_simo_multiligne',
    SIMO_PRO = 'plan_pro_simo',
    SOWO = 'plan_sowo',
    SOWO_PRO = 'plan_pro_sowo',
    SOWO_RENEWAL = 'plan_sowo_renew',
    SOWO_SPECIALS = 'plan_sowo_specials',
    IDEO = 'plan_ideo',
    IDEO_PRO = 'plan_ideo_pro',
    FAIM = 'faim',
    PREMIUM_PRO_SELECTION = 'plan_pro_premium_selection',
    SIMO_PRO_SELECTION = 'plan_pro_simo_selection',
    FAI = 'fai',
    FAIM_UNLIMITED = 'faim_unlimited',
    FAI_26 = 'fai-26',
    FAI_ELIGIBILITE = 'fai-eligibilite',
    FAI_ESSENTIEL = 'fai-essentiel',
    FAI_PRO = 'fai-pro',
    FAI_SERVICE = 'fai_service',
    OFFRES_AUTRES = 'offres_autres',
    FAI_BANQUE_WEB = 'FAI-BANQUE-WEB',
    FAI_SIMO = 'FAI-SIMO',
    FAI_SIMO_ELIGIBILITE = 'fai-simo-eligibilite',
    BELOW_THE_LINE_WEB = 'below_the_line_web',
    FAI_TABLETTE = 'fai-tablette',
    FAMILY_WEB = 'family-web',
    FAI_PREMIUM = 'fai-premium',
    FAI_SMART_TV = 'fai-smart-tv',
    FAI_VIDEOPROJECTEUR = 'fai-videoprojecteur',
    FAI_SMART_MONITOR = 'fai-smart-monitor',
    FAI_GAMING = 'fai-gaming',
    FAI_BYOU = 'fai-byou',
    SS_PREMIUM = 'FAI-SS-PREMIUM',
    FAI_PUREBYOU = 'fai-purebyou',
    FAI_5GBOX = 'fai-5gbox',
    FAI_BOX_INTERNET = 'fai-box-internet',
}

export enum MarketingType {
    CONVERGENCE = 'CONVERGENCE', // @deprecated Use MarketingType.HOME instead since Toutatis
    MULTILINE = 'MULTILIGNES',
    PORTABILITY = 'PORTABILITE',
    ZIP_CODE_FIXED_ELIGIBILITY = 'CODE_POSTAL_ELIGIBILITE_FIXE',
    SALES = 'SOLDES',
    HOME = 'FOYER',
    MOBILE = 'MOBILE',
}

export enum PlanRange {
    DEFAULT = '',
    WITH_MOBILE = 'forfait_avec_mobile',
    WITHOUT_MOBILE = 'forfait_sans_mobile',
    B_YOU = 'b_you',
}
