import { getAppConfig } from '@services/config';
import { axiosLoggerService } from '@services/loggers';

import { BytelAxiosRequestConfig } from '@app-types/axios';

import axios, { AxiosError, AxiosInstance, AxiosRequestConfig } from 'axios';
import { HttpProxyAgent } from 'http-proxy-agent';
import { HttpsProxyAgent } from 'https-proxy-agent';

const config = getAppConfig();

export function axiosInstanceFactory(external = false): AxiosInstance {
    const proxy = config.proxy.url;

    const axiosConfig: AxiosRequestConfig = {};

    if (external && proxy) {
        axiosConfig.proxy = false;
        axiosConfig.httpAgent = new HttpProxyAgent(proxy);
        axiosConfig.httpsAgent = new HttpsProxyAgent(proxy);
    }

    const defaultAxiosInstance = axios.create(axiosConfig);

    defaultAxiosInstance.interceptors.request.use((req) => {
        // custom axios options config
        const bytelConfig = req as BytelAxiosRequestConfig;
        bytelConfig.metadata = {
            startDate: Date.now(),
        };

        return bytelConfig;
    });

    defaultAxiosInstance.interceptors.response.use(
        (res) => {
            const config = res.config as BytelAxiosRequestConfig;

            if (config?.next?.mute !== true) {
                axiosLoggerService.logHttpResponse(res);
            }

            return res;
        },
        (error: AxiosError) => {
            const config = error.config as BytelAxiosRequestConfig;

            if (config?.next?.mute !== true) {
                axiosLoggerService.logHttpError(error);
            }

            return Promise.reject(error);
        },
    );

    return defaultAxiosInstance;
}
