import { AddressType } from '@app-types/address';

import { SapiProductType } from './api/sapi';

export type CartAddressType = {
    billing?: AddressType | null;
    shipping?: AddressType | null;
};

export enum PriceTypes {
    TODAY = 0,
    EVERY_MONTH = 1,
    OTHER = 2,
    REPORTED = 3,
    EVERY_MONTH_INCLUDED = 4,
}

export type CartProductType = {
    gencode: string;
    id?: number;
    isPrincipal: boolean;
    categories: string[];
    position: number;
    name: string;
    capacity: string;
    colorCode: string;
    color: string;
    total: number;
    productLine: string;
    gamme: string;
    prices: {
        base: number;
        final: number;
        forever: number;
        duration: number | null;
    };
    priceType: PriceTypes;
    autoAdd: string[];
    medias: string[];
    image?: string;
    smallImage?: string;
    imageHD?: string;
    maxQty: number;
    isPro: boolean;
    wallurl: string;
    proLabel: string;
    label: string;
    minPromoPrice: number;
    crossSells: string[];
    upSells: string[];
    related: string[];
    data: SapiProductType;
};

export type QuoteType = {
    context?: { id?: string };
    id?: number | null;
    idParcours?: number;
    products: CartProductType[];
    type?: string;
    address?: CartAddressType | null;
    forms?: Record<string, unknown>;
    lastProductParamsSent?: string;
};

export enum QUOTE_TYPES {
    ACQUISITION = 'ACQUISITION',
    ACQUISITION_FIXE = 'ACQUISITION_FIXE',
    ACQUISITION_FIXE_V4 = 'ACQUISITIONFIX',
    RENEWAL = 'RENOUVELLEMENT',
}
