import { SapicFundingType } from '@app-types/api/sapic';
import { DiscountEnumType } from '@app-types/discount';
import { FundingEnumType, FundingType } from '@app-types/funding';
import { PlanType } from '@app-types/plan';

export function fundingMapper(funding: SapicFundingType): FundingType {
    return {
        type: funding.type,
        favorite: funding.prefere,
        initialDeposit: funding.apportInitial,
        amountToFinance: funding.montantAFinancer,
        totalFinancingCost: funding.coutTotalFinancement,
        APR: funding.tAEG,
        interestAmount: funding.montantInteret,
        fixedInterestRate: funding.tauxDebiteurFixe,
        installmentCount: funding.nbrEcheances,
        label: getFundingLabels(funding),
        monthlyAmount: funding.montantMensualite,
    };
}

export function getFundingLabels(funding: SapicFundingType): string {
    switch (funding.type) {
        case FundingEnumType.COMPTANT:
            return 'Comptant';
        case FundingEnumType.EDP:
            return 'Facilité de paiement sans frais';
        default: {
            const fundingLabel = funding.tAEG === 0 ? `${funding.tAEG}%` : '';
            return `Financement smartphone ${fundingLabel}`;
        }
    }
}

export function getFundingPhone(product: PlanType, phoneGencode: string): FundingType[] {
    if (!product?.simulatedCart?.quotes[0]?.products) return [];

    const phone = product.simulatedCart.quotes[0]?.products.find((el) => el.gencode === phoneGencode);
    const fundings = phone?.details.fundings ?? [];
    const promotionODR = phone?.details.discounts.filter((promo) => promo.type === DiscountEnumType.ODR)[0]?.value ?? 0;

    if (fundings.length > 0) {
        return fundings.map((funding) => {
            const initialContributionWithOdr =
                promotionODR > 0 ? Math.max(funding.initialDeposit - promotionODR, 1) : funding.initialDeposit;

            funding.initialContributionWithOdr = initialContributionWithOdr;
            return funding;
        });
    } else {
        return [];
    }
}

export function getFundingOrder(type: FundingEnumType): number {
    switch (type) {
        case FundingEnumType.EDP:
            return 1;
        case FundingEnumType.COMPTANT:
            return 2;
        default:
            return 0;
    }
}

export function getDefaultFunding(fundings: FundingType[], presetValue?: string | null): FundingType | undefined {
    return fundings?.find((funding) => (presetValue ? funding.type === presetValue : funding.favorite)) || fundings[0];
}
