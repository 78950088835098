import { axiosInstanceFactory } from './axios-instance-factory';
import { AxiosService } from './axios-service';

process.env.NODE_TLS_REJECT_UNAUTHORIZED = '0';

export const httpService = new AxiosService(axiosInstanceFactory(false), false);
export const externalHttpService = new AxiosService(axiosInstanceFactory(true));

export const apiHttpService = new AxiosService(axiosInstanceFactory(false), true);
export const cmsHttpService = new AxiosService(axiosInstanceFactory(false), false, true);
