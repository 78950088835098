import { getAppConfig } from '@services/config';
import { httpService } from '@services/http';
import { CACHE_TAGS, HttpService } from '@services/http/http';

import { PalpatineProduct, PalpatineStickerType } from '@app-types/api/palpatine';
import { StaticCategoryApiResponseBase, StaticProductsAPI, StaticTabsResponse } from '@app-types/api/statics';

const appConfig = getAppConfig();

export class CmsService {
    private httpService: HttpService;

    public constructor(httpService: HttpService) {
        this.httpService = httpService;
    }

    public async getProduct(): Promise<{ items: PalpatineProduct[] }> {
        try {
            return this.httpService.get<{ items: PalpatineProduct[] }>(
                `${appConfig.assets.palpatine.product}/anim_fiche_produit`,
                undefined,
                {
                    ttl: parseInt(appConfig.cache.ttl.cms),
                    tags: [CACHE_TAGS.CMS],
                },
            );
        } catch (error) {
            console.log('Error while fetching palpatine product', error);
            return { items: [] };
        }
    }

    public async getRocProducts() {
        return this.httpService.get<StaticProductsAPI>(
            `${appConfig.assets.palpatine.fai}/fai_roc_products`,
            undefined,
            {
                ttl: parseInt(appConfig.cache.ttl.cms),
                tags: [CACHE_TAGS.CMS],
            },
        );
    }

    public async getRocTabs() {
        return this.httpService.get<StaticTabsResponse>(`${appConfig.assets.palpatine.fai}/fai_roc_tabs`, undefined, {
            ttl: parseInt(appConfig.cache.ttl.cms),
            tags: [CACHE_TAGS.CMS],
        });
    }

    public async getPack() {
        return this.httpService.get<StaticCategoryApiResponseBase>(
            `${appConfig.assets.palpatine.fai}/fai_samsung_page_pack`,
            undefined,
            {
                ttl: 0,
                tags: [CACHE_TAGS.CMS],
            },
        );
    }

    public async getStickers(url: string) {
        return this.httpService.get<PalpatineStickerType>(
            `${appConfig.assets.palpatine.banners}/banners_location/${url}`,
            {
                findByKey: 'slug',
            },
            {
                ttl: parseInt(appConfig.cache.ttl.cms),
                tags: [CACHE_TAGS.CMS],
            },
        );
    }
}

export const cmsService = new CmsService(httpService);
